import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TeamStatsCard.css';

const TeamStatsCard = (props) => {
  const { title, data } = props;
  const statsTitles = data && Object.keys(data);
  return (
    <ComponentWrapper className="c-TeamStatsCard">
      <h2>{title && title}</h2>
      <div className="stats-container">
        {statsTitles && statsTitles.map((title, i) => 
          <div className="stat" key={title + i}>
            <h3>{title.replace(/_/g, ' ')}</h3>
            <p>{data[title]}</p>
          </div>
        )}
      </div>
    </ComponentWrapper>
  );
};

TeamStatsCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  data: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired
  ])
}

export default TeamStatsCard;