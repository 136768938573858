import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  align-self: flex-start;
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 35.7rem;
  min-height: 15rem;
  width: 100%;
  a {
    color: var(--text);
    text-decoration: none;
    width: 100%;
  }
  img {
    margin-bottom: 1.5rem;
    max-width: 5rem;
  }
  .header {
    align-items: center;
    border-bottom: 1px solid var(--border);
    display: flex;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
    span {
      font-weight: 400;
      margin-left: 0.5rem;
      text-transform: none;
    }

  }
  .match-details {
    align-items: center;
    display: flex;
    justify-content: space-around;
    min-height: 7.7rem;
    padding: 2.5rem 3rem 2.5rem;
  }
  .team-container {
    display: flex;
  }
  .versus {
    color: var(--primary);
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .team-name {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: bold;
    justify-content: center;
    text-align: center;
  }
  .team-score {
    align-items: center;
    display: flex;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    margin-left: 3.5rem;
  }
  .team-2 {
    .team-score {
      margin: 0 3.5rem 0 0;
    }
  }
`;