import styled from 'styled-components';

const PageWrapper = styled.div`
  .center-wrapper {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 146.6rem;
    padding: 5rem;
    &.teams {
      padding: 5.3rem 0;
    }
  }
  .content-wrapper {
    justify-content: space-around;
    width: 100%;
  }
  .c-TeamCard {
    margin-bottom: 2.5rem;
  }
`;

export default PageWrapper;