import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  color: #FFF;
  height: 25rem;
  left: 0;
  position: absolute;
  max-height: 25rem;
  max-width: 156.6rem;
  top: 0;
  width: 100%;
  .header-content-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  .logo-container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    margin-left: 7%;
    max-height: 19rem;
    max-width: 19rem;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-left: initial;
      max-height: 14rem;
      max-width: 14rem;
    }
    img {
      width: 100%;
    }
  }
  .pattern-background {
    background-image: url('https://webcdn.hirezstudios.com/smite-esports/assets/TeamsHeaderTexture.png');
    background-repeat: repeat;
    opacity: 0.15;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
    &.gradient {
      background: rgb(42,42,42);
      background: linear-gradient(121deg, rgba(42,42,42,1) 0%, rgba(42,42,42,0.3) 40%, rgba(42,42,42,0) 100%);
      opacity: 1;
    }
  }
  .info-container {
    margin-left: 3rem;
    position: relative;
  }
  h1 {
    font-size: 5rem;
    @media only screen and (max-width: 768px) {
      font-size: 3rem;
    }
  }
  .social {
    margin-top: 1.5rem;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    a {
      color: #FFF;
      margin: 0 .5rem;
      &.is-hidden {
        display: none;
      }
      &:first-of-type {
        margin-left: initial;
      }
      &:hover {
        opacity: 0.75;
      }
      text-decoration: none;
      i {
        font-size: 3.5rem;
      }
    }
    &.mobile {
      background-color: #2A2A2A;
      display: none;
      margin-top: 0;
      padding: 1.8rem 0;
      text-align: center;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      a {
        margin: 0 0.5rem;
        i {
          font-size: 4.2rem;
        }
      }
    }
  }
`;