import React, { Component } from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './MatchScheduleCard.css';

class MatchScheduleCard extends Component {

  
  // sortDate = (a, b) => {
  //   let dateA = a.date;
  //   let dateB = b.date;
  //   let comparison = 0;
  //   if (dateA < dateB) {
  //     comparison = 1;
  //   }
  //   else if (dateA > dateB) {
  //     comparison = -1;
  //   }
  //   return comparison;
  // }

  filterCurrentPhase = (item) => {
    //Additional filter rule for Season 8 Phase 1 Group stage
    //Remove after group stages
    /*if (this.props.phase!='Phase 3' && item.phase === 'Phase 3 - Group A') {
      return true;
    }
    if (this.props.phase!='Phase 3' && item.phase === 'Phase 3 - Group B') {
      return true;
    }*/
    //standard rule
    if (this.props.phase === item.phase) {
      return true;
    }
    return false;
  }

  render() {
    const { data, stream_url, team } = this.props;
    return (
      <ComponentWrapper className="c-MatchScheduleCard">
        <h3>Match Schedule</h3>
        {data &&
        <>
        <Link className="scores-link" to={`/scores`} state={team ? { team: team } : {team:''}}>All Scores »</Link>
        <div className="schedule">
          {data && data.map((item) =>{
            {this.newDate=new Date(item.date*1000).toLocaleDateString(navigator.language,{weekday: 'long', month: 'long', day: 'numeric'})!==this.lastDate?true:false}
            {this.lastDate=new Date(item.date*1000).toLocaleDateString(navigator.language,{weekday: 'long', month: 'long', day: 'numeric'})}

            return(
              <div key={item.date} className={this.newDate?"newday":"day"}>
                
              {this.newDate && <div className="date">{new Date(item.date*1000).toLocaleDateString(navigator.language,{weekday: 'long', month: 'long', day: 'numeric'})}</div>}
              {item.matches.map((match_item) =>
                <div key={match_item.time + match_item.team_1_shortname + match_item.team_2_shortname} className="match">
                  <div className="time">{new Date(match_item.time*1000).toLocaleTimeString(navigator.language,{ hour: 'numeric', minute:'2-digit',hour12: true, timeZoneName: 'short' })}</div>
                  <div className="info">
                    <div className="team_names">
                      <div className={`team team-home ${match_item.team_1_score !== null && match_item.team_2_score !== null && match_item.team_1_score < match_item.team_2_score ? 'is-faded' : ''}`}>
                        <div className="logo-wrapper"><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.props.context.darkTheme ? 'dark/' : ''}${match_item.team_1_shortname}.png`} alt={item.team_1_shortname} /></div>
                        <div className="name">{match_item.team_1_shortname}</div>
                        
                      </div>
                      <div className={`team team-away ${match_item.team_1_score !== null && match_item.team_2_score !== null && match_item.team_1_score > match_item.team_2_score ? 'is-faded' : ''}`}>
                        <div className="logo-wrapper"><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.props.context.darkTheme ? 'dark/' : ''}${match_item.team_2_shortname}.png`} alt={item.team_2_shortname} /></div>
                        <div className="name">{match_item.team_2_shortname}</div>
                      </div>
                    </div>
                    <div className="team_scores splr">
                      <div className={`team team-home ${match_item.team_1_score !== null && match_item.team_2_score !== null && match_item.team_1_score < match_item.team_2_score ? 'is-faded' : ''}`}>
                        
                        <div className="score team_score">{match_item.team_1_score}</div>
                      </div>
                      <div className={`team team-away ${match_item.team_1_score !== null && match_item.team_2_score !== null && match_item.team_1_score > match_item.team_2_score ? 'is-faded' : ''}`}>
                        
                        <div className="score team_score">{match_item.team_2_score}</div>
                      </div>
                    </div>
                    <div className={`actions ${match_item.team_1_score || match_item.team_2_score ? '' : 'is-live'}`}>
                      {match_item.team_1_score || match_item.team_2_score ?
                        <>
                          {match_item.playlist_url && <a href={match_item.playlist_url}><i className="icon-play" />Videos</a>}
                          <Link to={`/matches/${match_item.match_id}`}><i className="icon-calendar" />Full Results</Link>
                        </>
                        :
                        <div>
                          <a href={stream_url}><i className="icon-play" />Watch Live</a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
            )}
            
          )}
        </div>
        </>
        }
      </ComponentWrapper>
    );
  };
};

MatchScheduleCard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ])
}

export default withContext(MatchScheduleCard);