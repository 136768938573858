import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 117rem;
  width: 100%;
  .full-roster {
    padding: 3rem 0 3rem 3rem;
  }
  h2 {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }
  .roster-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 83rem;
  }
  .player-container {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    margin: 2rem 1.5rem;
    min-width: 23.6rem;
    text-decoration: none;
  }
  .game-name {
    line-height: 2.2;
    text-transform: uppercase;
  }
  .real-name {
    font-size: 1.2rem;
  }
  .image-wrapper {
    background: #F1F1F1;
    border-radius: 50%;
    overflow: hidden;
    height: 9rem;
    width: 9rem;
    img {
      height: auto;
      width: 100%;
    }
  }
  .player-info {
    color: var(--text);
    margin-left: 2rem;
  }
  .leaders {
    border-left: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 27rem;
    padding: 3rem;
  }
  .leader {
    margin: 2rem 0rem;
  }
  .leader-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
    position: relative;
    &:after {
      background-color: var(--primary);
      bottom: -0.8rem;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 3.5rem;
    }
  }
  .stat {
    font-size: 3rem;
    font-weight: bold;
  }
  .none {
    color: var(--secondary-text);
  }
`;