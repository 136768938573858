import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  height: 76.2rem;
  /* max-width: 25rem; */
  overflow-y: scroll;
  padding: 3rem 0;
  width: 25rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--text);
    background-color: var(--text);
  }
  &::-webkit-scrollbar {
    background-color: var(--text);
    height: .3rem;
    width: .3rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
  }
  h3 {
    color: var(--text);
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0 3rem;
  }
  .scores-link {
    color: var(--primary);
    font-size: 1.4rem;
    display: block;
    padding: 1rem 3rem 0 3rem;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .schedule {
    .newday {
      color: var(--text);
      margin-top: 1.6rem;
      .date {
        border-bottom: .1rem solid var(--border);
      }
    }
    .day {
      color: var(--text);
      .match{
        &:first-of-type {
        border-top: .1rem solid var(--border);
      }
      }
      .date {
        border-bottom: .1rem solid var(--border);
      }
      
    }
    .date {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0 3rem .5rem 3rem;
    }
    .match {
      padding: .5rem 3rem 0rem 3rem;
      &:not(:last-of-type) {
        border-bottom: .1rem solid var(--border);
      }
      .time {
        font-size: 1.2rem;
        margin-top: .5rem;
      }
      .info {
        display: flex;
        align-items:center;
        margin-top: .5rem;
        .team_names, .team_scores {
          padding: .8rem 0 .5rem 0;
          min-width: 7.5rem;
          .team, .score-line {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: left;
            &:first-of-type {
              margin-bottom: .5rem;
            }
            &.is-faded {
              .name, .score {
                opacity: 0.5;
                
              }
            }
            img {
              max-height: 2rem;
              max-width: 2rem;
              /* transform: translateY(-4px); */
            }
            .logo-wrapper{
              width:2rem;
              height:2rem;
              min-width: 2rem;
              min-height: 2rem;
              max-width:2rem;
              max-height:2rem;
            }
            .name {
              align-items: center;
              display: flex;
              min-width: 2.6rem;
              padding: 0 2rem 0 .9rem;
            }
          }
        }
        .team_scores{
          min-width:min-content;
        }
        .score-line{
          margin: 0.5rem;
          height: 3rem;
          cursor:pointer;
        }
        
        .team_score{
          width: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 2rem;
          height: 2rem;
          min-width: 2rem;
          min-height: 2rem;
          max-width: 2rem;
          max-height: 2rem;
        }
        .actions {
          padding: .7rem 0 .8rem 3rem;
          width: 50%;
          white-space:nowrap;
          @media screen and (max-width: 768px) {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
          }
          &.is-live {
            align-items: center;
            display: flex;
          }
          a {
            color: var(--text);
            font-size: 1.2rem;
            display: block;
            text-decoration: none;
            i {
              color: var(--text);
            }
            &:hover {
              text-decoration: underline;
            }
            &:first-of-type {
             
              @media screen and (max-width: 768px) {
                margin-bottom: initial;
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
`;