import React, { Component } from 'react';
import withContext from 'store/withContext';
import { ComponentWrapper } from './TeamContainer.css';
import TeamHeader from 'components/Teams/TeamHeader';
import MatchScheduleCard from 'components/Global/MatchScheduleCard';
import TeamRoster from 'components/Teams/TeamRoster';
import GameSummaryCard from 'components/Teams/GameSummaryCard';
import {StandingsCard} from 'components/Global/StandingsCard';
import TeamStatsCard from 'components/Teams/TeamStatsCard';
// import { Link } from 'gatsby';

class TeamContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getTeam = (id, logo) => {
    fetch(`${this.props.context.baseUrl}/team/${id}/${this.props.context.eventId}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        teamInfo: response,
        logo: logo
      });
    });
  }

  getTeamSchedule = (id) => {
    fetch(`${this.props.context.baseUrl}/schedule/${this.props.context.eventId}/${id}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        teamSchedule: response
      });
    });
  }

  getAdjacentGames = () => {
    // const now = 1569605500;
    const flatSchedule = this.getFlatScheduleArray();

    for (let i = 0; i < flatSchedule.length; i++) {
      if (i === 0 && flatSchedule[i].time > this.props.context.now) {
        this.setState({
          prev: null,
          next: flatSchedule[i]
        });
        break;
      }
      else if ((i === flatSchedule.length - 1) && flatSchedule[i].time < this.props.context.now) {
        this.setState({
          prev: flatSchedule[i],
          next: null
        });
        break;
      }
      else if (flatSchedule[i].time < this.props.context.now && flatSchedule[i + 1].time > this.props.context.now) {
        this.setState({
          prev: flatSchedule[i],
          next: flatSchedule[i + 1]
        });
        break;
      }
    }
  }

  getFlatScheduleArray = () => {
    let flatArray = [];
    this.state.teamSchedule.schedule.forEach(day => {
      day.matches.forEach(match => {
        flatArray.push(match);
      });
    });
    return flatArray;
  }

  getTeamInfo = () => {
    const activeTeam = this.props.context.teams.find(team => team.tag.toLowerCase() === this.props.team);
    if(!activeTeam){
      window.location.href="/teams"
    }
    this.getTeam(activeTeam.team_id, activeTeam.logo);
    this.getTeamSchedule(activeTeam.team_id);
  }

  componentDidMount() {
    if (this.props.context.teams && !this.state.teamInfo) {
      this.getTeamInfo();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.context.teams && !this.state.teamInfo || prevProps.team !== this.props.team) {
      this.getTeamInfo();
    }
    if (!prevState.teamSchedule && this.state.teamSchedule) {
      this.getAdjacentGames();
    }
  }

  render() {
    return (
      <ComponentWrapper className="c-TeamContainer">
        <div className="center-wrapper">
          <TeamHeader 
            name={this.state.teamInfo && this.state.teamInfo.name}
            tag={this.state.teamInfo && this.state.teamInfo.short_name} links={this.state.teamInfo && this.state.teamInfo.links}
            color={this.state.teamInfo && this.state.teamInfo.color} />
          <div className="content-wrapper">
            <MatchScheduleCard
              stream_url="https://twitch.tv/smitegame"
              data={this.state.teamSchedule && this.state.teamSchedule.schedule}
              team={this.state.teamSchedule && this.state.teamSchedule.requested_team}
              phase={this.props.context.schedule !== null && this.props.context.schedule.phases[this.props.context.schedule.phases.length - 1].title}
            />
            <div className="right-block">
              <TeamRoster roster={this.state.teamInfo && this.state.teamInfo.roster} leaders={this.state.teamInfo && this.state.teamInfo.leaders} />
              <div className="bottom-block">
                <GameSummaryCard  data={this.state.prev && this.state.prev} prev darkTheme={this.props.context.darkTheme} />
                <GameSummaryCard  data={this.state.next && this.state.next} next darkTheme={this.props.context.darkTheme} />
                <TeamStatsCard title={`${process.env.GATSBY_LEAGUE_HEADER} Team Stats`} data={this.state.teamInfo && this.state.teamInfo.current_season} />
              </div>
              <StandingsCard
                season={process.env.GATSBY_LEAGUE_HEADER}
                header={this.state.teamInfo&&this.state.teamInfo.region===3379?'EU Phase 1':this.state.teamInfo&& this.state.teamInfo.region===3378?'NA Phase 1':''}
                data={this.state.teamInfo&&this.state.teamInfo.region!==3379?this.props.context.standings !== null && this.props.context.standings:this.props.context.standings2 !== null && this.props.context.standings2}
                team={this.props.team}
              />
            </div>
          </div>
        </div>
      </ComponentWrapper>
    );
  }
};

export default withContext(TeamContainer);