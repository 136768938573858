import React from 'react';
import { ComponentWrapper } from './TeamRoster.css';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const TeamRoster = (props) => {
  const { roster, leaders } = props;
  return (
    <ComponentWrapper className="c-TeamRoster">
      <div className="full-roster">
        <h2>Full Roster</h2>
        <div className="roster-container">
          {roster && roster.map((player, i) => 
          <Link className="player-container" to={`/players/${player.name}`} key={player.name}>
            <div className="image-wrapper">
              <img src={`https://webcdn.hirezstudios.com/smite-esports/players/${player.name.toLowerCase()}.png`} />
            </div>
            <div className="player-info">
              <p className="game-name">{player.name}</p>
              <p className="real-name">{player.full_name} | {player.role}</p>
            </div>
          </Link>
          )}
        </div>
      </div>
      <div className="leaders">
        <h2>High Performers</h2>
        {leaders && leaders.length > 0 && leaders.map((leader, i) => 
        <Link className="leader player-container" to={`/players/${leader.name}`} key={leader.name + i}>
          <div className="image-wrapper">
            <img src={`https://webcdn.hirezstudios.com/smite-esports/players/${leader.name.toLowerCase()}.png`} />
          </div>
          <div className="player-info">
            <p className="leader-title">{leader.title}</p>
            <p className="game-name">{leader.name}</p>
            <p className="stat">{leader.stat}</p>
          </div>
        </Link>
        )}
        {leaders && leaders.length <= 0 &&
          <p className="none">None</p>
        }
      </div>
    </ComponentWrapper>
  );
};

TeamRoster.propTypes = {
  roster: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ]),
  leaders: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ])
}

export default TeamRoster;