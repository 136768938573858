import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { ComponentWrapper } from './GameSummaryCard.css';

const GameSummaryCard = (props) => {
  const { prev, next, data, darkTheme} = props;
  return (
    <ComponentWrapper className="c-GameSummaryCard">
      <Link to={`/matches/${data && data.match_id}`} style={!data ? {pointerEvents: 'none'}: {}}>
        <div className="header">{prev ? 'Last': 'Next'} Game <span>{data && new Date(data.time*1000).toLocaleDateString(navigator.language,{weekday: 'long', month: 'short', day: 'numeric'})}</span></div>
        <div className="match-details">
          <div className="team-container team-1">
            <div className="team-name">
              <img src={data && `https://webcdn.hirezstudios.com/smite-esports/dev/teams/${darkTheme ? 'dark/' : ''}${data.team_1_shortname}.png`} />
              <p>{data && data.team_1_shortname}</p>
            </div>
            <div className="team-score">{(data && data.team_1_score !== '') ? data.team_1_score : '-'}</div>
          </div>
          <div className="versus">{(data && data.winning_team) ? 'Final' : ((data && data.in_progress) ? 'Live Now' : 'VS' )}</div>
          <div className="team-container team-2">
            <div className="team-score">{(data && data.team_2_score !== '') ? data.team_2_score : '-'}</div>
            <div className="team-name">
              <img src={data && `https://webcdn.hirezstudios.com/smite-esports/dev/teams/${darkTheme ? 'dark/' : ''}${data.team_2_shortname}.png`} />
              <p>{data && data.team_2_shortname}</p>
            </div>
          </div>
        </div>
      </Link>
    </ComponentWrapper>
  );
};

GameSummaryCard.propTypes = {
  next: PropTypes.bool,
  prev: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ])
}

export default GameSummaryCard;