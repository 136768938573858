import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  .center-wrapper {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    margin: 0 auto;
    max-width: 146.6rem;
    padding: 5rem;
    position: relative;
  }
  .content-wrapper {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap-reverse;
    margin-top: 20rem;
    @media only screen and (max-width: 768px) {
      margin-top: 30rem;
    }
  }
  .right-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5rem;
    max-width: 117rem;
    width: 100%;
  }
  .bottom-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 73.5rem;
    width: 100%;
  }
  .c-MatchScheduleCard {
    margin-top: 5rem;
    height: 104.9rem;
  }
  .c-GameSummaryCard {
    margin-top: 2.5rem;
  }
  .c-StandingsCard {
    margin-top: 2.5rem;
    max-width: 41.4rem;
    table {
      font-size: 1.2rem;
      img {
        margin: 0 1rem 0 1.5rem;
      }
      tr td {
        &:nth-of-type(1) {
          text-align: right;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 0;
    .center-wrapper {
      padding: 0;
    }
    .c-MatchScheduleCard,
    .c-GameSummaryCard,
    .c-StandingsCard,
    .c-TeamStatsCard,
    .bottom-block {
      max-width: none;
      width: 100%;
    }
    .c-TeamStatsCard {
      .stat {
        min-width: 10rem;
      }
      p {
        font-size: 2rem;
      }
    }
    .c-MatchScheduleCard {
      max-width: none;
      width: 100%;
      .schedule .match .info .actions {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        a {
          margin: 0 1rem;
        }
      }
    }
  }
`;