import React, { Component } from 'react';
import withContext from 'store/withContext';
import TeamCard from 'components/Teams/TeamCard';
import TeamContainer from 'components/Teams/TeamContainer';

import PageWrapper from '../styles/teams.css';
// import { isThisSecond } from 'date-fns';

class Teams extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isArchive: 'unknown'
    };
  }

  componentDidMount() {
    this.props.location.pathname === '/teams/' ? this.setState({isArchive: 'yes'}) : this.setState({isArchive: 'no', teamName: this.props.location.pathname.split('/')[2]});
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.location.pathname === '/teams/' ? this.setState({isArchive: 'yes'}) : this.setState({isArchive: 'no', teamName: this.props.location.pathname.split('/')[2]});
    }
  }

  render() {
    const { teams } = this.props.context;

    return (
      <PageWrapper>
        {this.state.isArchive === 'yes' &&
        <div className="center-wrapper teams">
          {teams && teams.map((team, i) => 
          <TeamCard name={team.name} logo={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.props.context.darkTheme ? 'dark/' : ''}${team.tag}.png`} key={team.logo} tag={team.tag} />
          )}
        </div>
        }
        {this.state.isArchive === 'no' &&
        <TeamContainer team={this.state.teamName} darkTheme={this.props.context.darkTheme} />
        }
      </PageWrapper>
    );
  }
}

export default withContext(Teams);
