import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TeamHeader.css';

const TeamHeader = (props) => {
  const { name, logo, tag, color, links} = props;
  return (
    <ComponentWrapper className="c-TeamHeader" style={{background: `transparent linear-gradient(261deg, ${color ? color : '#b1966f'} 0%, #2a2a2a 100%) 0% 0% no-repeat padding-box`}}>
      <div className="header-content-wrapper">
        <div className="pattern-background" />
        <div className="pattern-background gradient" />
        <div className="logo-container">
          <img src={tag && `https://webcdn.hirezstudios.com/smite-esports/dev/teams/dark/${tag}.png`} />
        </div>
        <div className="info-container">
          <h1>{name}</h1>
          <div className="social">
            {links && links.map((link, i) => 
            <a className={`${link.service.toLowerCase()} ${link.url === null ? 'is-hidden' : ''}`} href={link.url} target="_blank" key={link.service + i}>
              <i className={`icon-${link.service.toLowerCase()}`} />
            </a>
            )}
          </div>
        </div>
      </div>
      <div className="social mobile">
        {links && links.map((link, i) => 
        <a className={`${link.service.toLowerCase()}`} href={link.url} target="_blank" key={link.service + i}>
          <i className={`icon-${link.service.toLowerCase()}`} />
        </a>
        )}
      </div>
    </ComponentWrapper>
  );
};

TeamHeader.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  logo: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  tag: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  links: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ])
}

export default TeamHeader;