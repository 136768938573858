import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  align-self: flex-start;
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  font-weight: bold;
  justify-content: space-between;
  min-height: 15rem;
  margin-top: 2.5rem;
  max-width: 73.4rem;
  padding: 2rem 2.5rem;
  width: 100%;
  h2 {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    width: 100%;
  }
  .stats-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
  .stat {
    margin-top: 2rem;
    min-width: 7.5rem;
    padding-bottom: 2.5rem;
    position: relative;
    width: 25%;
    &:after {
      background-color: var(--primary);
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 7.5rem;
    }
  }
  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 3rem;
  }
`;